import { NgModule } from '@angular/core';

import { CurrencySymbolPipe } from '@locumsnest/core/src/lib/pipes';

import { ActionPerformerPipe } from './action-performer/action-performer.pipe';
import { AttributeCodePipe, AttributeDisplayPipe } from './attributes/attribute';
import { OrderByPipe } from './collection/order.pipe';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { CompactDatePipe } from './date/compact-date.pipe';
import { DateRangePipe } from './date/date-range.pipe';
import { DateTimePipe } from './date/date-time.pipe';
import { DatePipe } from './date/date.pipe';
import { TimePipe } from './date/time.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { FromNowPipe } from './from-now/from-now.pipe';
import { KebabcasePipe } from './kebabcase/kebabcase.pipe';
import { ReplaceHeadersTagsPipe } from './replace-headers-tags/replace-headers-tags.pipe';
import { ReplaceHtmlTagsPipe } from './replace-html-tags/replace-html-tags.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TextDurationFromSecondsPipe } from './text-duration-from-seconds/text-duration-from-seconds.pipe';
import { TextTruncatePipe } from './text-truncate/text-truncate.pipe';
import { TimeDurationTextPipe } from './time-duration-text/time-duration-text.pipe';

@NgModule({
  imports: [
    KebabcasePipe,
    TextTruncatePipe,
    DatePipe,
    CompactDatePipe,
    CurrencySymbolPipe,
    DateTimePipe,
  ],
  declarations: [
    ActionPerformerPipe,
    AttributeCodePipe,
    AttributeDisplayPipe,
    DateFormatPipe,
    DateRangePipe,
    OrderByPipe,
    ReplaceHeadersTagsPipe,
    ReplaceHtmlTagsPipe,
    SafeUrlPipe,
    TextDurationFromSecondsPipe,
    TimeDurationTextPipe,
    TimePipe,
    FromNowPipe,
    FileSizePipe,
  ],
  exports: [
    ActionPerformerPipe,
    AttributeCodePipe,
    AttributeDisplayPipe,
    CompactDatePipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    DatePipe,
    DateRangePipe,
    DateTimePipe,
    KebabcasePipe,
    OrderByPipe,
    ReplaceHeadersTagsPipe,
    ReplaceHtmlTagsPipe,
    SafeUrlPipe,
    TextDurationFromSecondsPipe,
    TextTruncatePipe,
    TimeDurationTextPipe,
    TimePipe,
    FromNowPipe,
    FileSizePipe,
  ],
})
export class PipesModule {}
