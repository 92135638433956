import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { TimeString } from '@locumsnest/core/src/lib/types';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

/**
 * Formats to hours if base date is the same as given date otherwise formats as date
 *@
 * @export
 * @class DateTime
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'dateTime',
  standalone: true,
})
@Injectable()
export class DateTimePipe implements PipeTransform {
  constructor() {}
  transform(date: Date, time: TimeString, dateFormat: string = DATE_FORMAT, ...args): string {
    return Time.formatDate(date, dateFormat) + ' - ' + time;
  }
}
