import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

@Pipe({ name: 'date', pure: true, standalone: true })
export class DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: any, format = DATE_FORMAT): string | null {
    if (value == null || value === '' || value !== value) return null;
    return moment(value).format(format);
  }
}
